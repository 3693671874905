<template>
  <page-layout ready>
    <template slot="breadcrumb">
      <el-breadcrumb-item
        ><h1 class="product-title">{{ $t('product.productManagement.title') }}</h1></el-breadcrumb-item
      >
    </template>
    <Tabs class="mb-4 d-flex justify-space-around" :tabs="tabs" :active-tab.sync="activeTab" />
    <ProductsTable
      v-show="activeTab === TABS.PRODUCTS"
      :loading="loading"
      :products="products"
      :suppliers="suppliers"
      :limit="limit"
      :total-count="totalCount"
      :page="page"
      @on-supplier-search="updateSupplierFilter"
      @on-product-search="onSearch"
      @on-page-change="page = $event"
      @on-product-click="selectedProductId = $event"
      @on-products-remove-from-catalogs="onProductsRemoveFromCatalogs"
    />
    <CategoriesList v-show="activeTab === TABS.CATEGORIES" :category-trees="categoryTrees" />
    <Catalogs v-if="activeTab === TABS.CATALOGS" />
    <ProductManagementModal
      v-if="selectedProductId"
      :product-id="selectedProductId"
      :category-trees="categoryTrees"
      @closed="onCloseProductManagementModal"
    />
  </page-layout>
</template>

<script>
import { computed, getCurrentInstance, ref } from 'vue';

import { Tabs } from '@/modules/core/components';

import { useProductsNew, useCategories, useSuppliersNew } from '../../compositions';
import { useCatalogs } from '../../../catalog/compositions/useCatalog';
import ProductsTable from './ProductsTable';
import ProductManagementModal from '../productManagementModal/ProductManagementModal';
import CategoriesList from './CategoriesList';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';
import Catalogs from '@/modules/catalog/components/Catalogs.vue';

const CATALOG_LIMIT = 500;
const TABS = {
  PRODUCTS: 0,
  CATEGORIES: 1,
  CATALOGS: 2,
};
export default {
  components: { Tabs, ProductsTable, ProductManagementModal, CategoriesList, Catalogs },
  setup() {
    const root = getCurrentInstance().proxy;
    const page = ref(1);
    const limit = ref(20);
    const searchValue = ref('');
    const supplierId = ref('');
    const selectedProductId = ref();
    const activeTab = ref(0);
    const onSearch = (value) => {
      searchValue.value = value;
      page.value = 1;
    };

    const updateSupplierFilter = (value) => {
      supplierId.value = value;
      page.value = 1;
    };

    const pagination = computed(() => ({
      limit,
      offset: limit.value * (page.value - 1),
      search: searchValue.value,
      businessId: supplierId.value,
    }));

    const { products, loading: productsLoading, totalCount, refetch: refetchProducts } = useProductsNew(pagination);
    const { suppliers, loading: suppliersLoading } = useSuppliersNew();
    const { categoryTrees, loading: categoriesLoading } = useCategories();
    const {
      allResults: catalogs,
      loading: catalogLoading,
      refetch: refetchCatalogs,
    } = useFetchAll({
      limit: CATALOG_LIMIT,
      dataSelector: 'catalogs',
      hook: useCatalogs,
      variables: {},
    });

    const loading = computed(
      () =>
        (productsLoading.value && categoriesLoading.value && catalogLoading.value) ||
        productsLoading.value ||
        suppliersLoading.value
    );

    const mappedProducts = computed(() => {
      return products.value.map((product) => {
        const relevantCatalogs = catalogs.value.filter(({ productIds }) => productIds.includes(product.id));

        return {
          ...product,
          catalogs: relevantCatalogs,
          availability: !catalogLoading.value ? relevantCatalogs.length > 0 : undefined,
        };
      });
    });

    const onCloseProductManagementModal = () => {
      selectedProductId.value = null;
      refetchCatalogs();
      refetchProducts();
    };

    const onProductsRemoveFromCatalogs = () => {
      refetchCatalogs();
      refetchProducts();
    };

    const tabs = computed(() => [
      {
        text: root.$t('product.productManagement.tab.products'),
      },
      {
        text: root.$t('product.productManagement.tab.categories'),
      },
      {
        text: root.$t('catalog.title'),
      },
    ]);

    return {
      TABS,
      tabs,
      suppliers,
      categoryTrees,
      selectedProductId,
      limit,
      page,
      products: mappedProducts,
      loading,
      totalCount,
      activeTab,
      searchValue,
      onSearch,
      updateSupplierFilter,
      onCloseProductManagementModal,
      onProductsRemoveFromCatalogs,
    };
  },
};
</script>
<style lang="scss">
.product-title {
  color: #1b1e22;
}
</style>
