import i18n from '@/imports/startup/client/i18n';
import moment from 'moment';

export const PRODUCT_TABLE_HEADERS = {
  SUPPLIER: 'supplier',
  NAME: 'name',
  SKU: 'sku',
  GTIN: 'GTIN',
  LAST_ORDER: 'lastOrder',
  ASSOCIATED_PRODUCT: 'associatedProduct',
  REFERENCES: 'references',
  CATEGORY: 'categoryId',
  CATALOGS: 'catalogs',
  AVAILABILITY: 'availability',
};

export function getProductsTableData(product, isSupplierView, lastOrders) {
  if (isSupplierView) {
    return {
      id: product.id,
      [PRODUCT_TABLE_HEADERS.NAME]: product.name,
      [PRODUCT_TABLE_HEADERS.SKU]: product.sku,
      [PRODUCT_TABLE_HEADERS.CATALOGS]: product.catalogs,
      [PRODUCT_TABLE_HEADERS.AVAILABILITY]: product.availability,
    };
  }

  return {
    id: product.id,
    [PRODUCT_TABLE_HEADERS.SUPPLIER]: product.business ?? '-',
    [PRODUCT_TABLE_HEADERS.SKU]: product.sku,
    [PRODUCT_TABLE_HEADERS.GTIN]: product.gtin,
    [PRODUCT_TABLE_HEADERS.NAME]: product.name,
    [PRODUCT_TABLE_HEADERS.LAST_ORDER]: lastOrders?.find((lastOrder) => lastOrder.productId === product.id)?.lastOrder,
    [PRODUCT_TABLE_HEADERS.ASSOCIATED_PRODUCT]:
      product.associatedProducts.length === 0 ? '-' : product.associatedProducts.length,
    [PRODUCT_TABLE_HEADERS.REFERENCES]: product.references.length === 1 ? '-' : product.references.length,
    [PRODUCT_TABLE_HEADERS.CATEGORY]: product.categoryId,
    [PRODUCT_TABLE_HEADERS.CATALOGS]: product.catalogs,
    [PRODUCT_TABLE_HEADERS.AVAILABILITY]: product.availability,
  };
}

export function getProductsTableColumns(isSupplierView, supplierIdFilter, activeSort, catalogSearchFilter) {
  if (isSupplierView) {
    return [
      {
        header: i18n.t('commons.name'),
        key: PRODUCT_TABLE_HEADERS.NAME,
        width: '18.75rem',
        sortable: true,
        sortCallback: (direction) => {
          activeSort.direction = direction;
          activeSort.columnKey = PRODUCT_TABLE_HEADERS.NAME;
        },
      },
      {
        header: i18n.t('commons.sku'),
        key: PRODUCT_TABLE_HEADERS.SKU,
        width: '7.25rem',
      },
      {
        header: i18n.t('product.productManagement.table.header.catalog'),
        key: PRODUCT_TABLE_HEADERS.CATALOGS,
        width: '18.75rem',
        filterActive: !!catalogSearchFilter,
        isFilterable: true,
      },
      {
        header: i18n.t('catalog.products.table.headers.availability'),
        key: PRODUCT_TABLE_HEADERS.AVAILABILITY,
        width: '18.75rem',
        sortable: true,
        sortCallback: (direction) => {
          activeSort.direction = direction;
          activeSort.columnKey = PRODUCT_TABLE_HEADERS.AVAILABILITY;
        },
      },
      {
        header: '',
        width: '60px',
        key: 'kebab',
        customClass: 'p-0',
      },
    ];
  }

  return [
    {
      header: i18n.t('commons.name'),
      key: PRODUCT_TABLE_HEADERS.NAME,
      width: '15rem',
    },
    {
      header: i18n.t('commons.sku'),
      key: PRODUCT_TABLE_HEADERS.SKU,
      width: '7.25rem',
    },
    {
      header: i18n.t('product.productManagement.table.header.supplier'),
      key: PRODUCT_TABLE_HEADERS.SUPPLIER,
      filterActive: !!supplierIdFilter,
      isFilterable: true,
      width: '18.75rem',
    },
    {
      header: i18n.t('product.productManagement.table.header.gtin'),
      key: PRODUCT_TABLE_HEADERS.GTIN,
      width: '5rem',
    },
    {
      header: i18n.t('product.productManagement.table.header.lastOrder'),
      key: PRODUCT_TABLE_HEADERS.LAST_ORDER,
      width: '8rem',
    },
    {
      header: i18n.t('product.productManagement.table.header.associatedProduct'),
      key: PRODUCT_TABLE_HEADERS.ASSOCIATED_PRODUCT,
      width: '8rem',
    },
    {
      header: i18n.t('product.productManagement.table.header.references'),
      key: PRODUCT_TABLE_HEADERS.REFERENCES,
      width: '8rem',
    },
    {
      header: i18n.t('commons.category'),
      key: PRODUCT_TABLE_HEADERS.CATEGORY,
      width: '15rem',
    },
    {
      header: i18n.t('product.productManagement.table.header.catalog'),
      key: PRODUCT_TABLE_HEADERS.CATALOGS,
      width: '15rem',
    },
    {
      header: i18n.t('catalog.products.table.headers.availability'),
      key: PRODUCT_TABLE_HEADERS.AVAILABILITY,
      width: '8rem',
    },
    {
      header: '',
      width: '60px',
      key: 'kebab',
      customClass: 'p-0',
    },
  ];
}

export function sortProducts(products, sortKey, sortDirection) {
  return products.sort((a, b) => {
    const aValue = a[sortKey];
    const bValue = b[sortKey];

    const aIsEmpty =
      typeof aValue === 'undefined' || aValue === null || (typeof aValue === 'string' && aValue.trim() === '');
    const bIsEmpty =
      typeof bValue === 'undefined' || bValue === null || (typeof bValue === 'string' && bValue.trim() === '');

    if (aIsEmpty && !bIsEmpty) return 1;
    if (!aIsEmpty && bIsEmpty) return -1;
    if (aIsEmpty && bIsEmpty) return 0;

    if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
      return (aValue === bValue ? 0 : aValue ? 1 : -1) * sortDirection;
    }

    const isADate = moment(aValue, moment.ISO_8601, true).isValid();
    const isBDate = moment(bValue, moment.ISO_8601, true).isValid();

    if (isADate && isBDate) {
      return (moment(aValue).isAfter(moment(bValue)) ? 1 : -1) * sortDirection;
    }

    return ((aValue > bValue) - (aValue < bValue)) * sortDirection;
  });
}
