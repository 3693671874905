<template>
  <el-tree
    :data="categoryTrees"
    draggable
    :props="{ label: 'name' }"
    node-key="_id"
    :expand-on-click-node="false"
    @node-drop="onNodeDrop"
  >
    <template #default="{ node, data }">
      <div class="d-flex align-items-center justify-content-between w-100 pe-2">
        <span class="el-tree-node__label">{{ node.label }}</span>
        <span>
          <el-popover :value="editedCategoryId === node.key" trigger="manual" placement="right">
            <div class="d-flex">
              <el-input v-model="nameModel" size="mini" class="pe-2" />
              <el-button type="primary" size="mini" icon="el-icon-check" @click="editCategory" />
              <el-button size="mini" icon="el-icon-close" @click="editedCategory = null" />
            </div>
            <el-button slot="reference" type="text" size="mini" @click="editedCategory = data">Edit</el-button>
          </el-popover>
          <el-popover :value="appendedCategoryId === node.key" trigger="manual" placement="right">
            <div class="d-flex">
              <el-input v-model="nameModel" size="mini" class="pe-2" />
              <el-button type="primary" size="mini" icon="el-icon-check" @click="appendCategory" />
              <el-button size="mini" icon="el-icon-close" @click="appendedCategory = null" />
            </div>
            <el-button slot="reference" type="text" size="mini" @click="appendedCategory = data">Append</el-button>
          </el-popover>
        </span>
      </div></template
    >
  </el-tree>
</template>

<script>
import { updateCategory, createCategory } from '@/modules/products/compositions/products-operations';
import { CategorySchema } from '@/modules/products/models/category';

export default {
  props: {
    categoryTrees: { type: Array, default: () => [] },
  },
  data() {
    return {
      editedCategory: null,
      nameModel: '',
      appendedCategory: null,
    };
  },
  computed: {
    editedCategoryId() {
      return this.editedCategory && this.editedCategory._id;
    },
    appendedCategoryId() {
      return this.appendedCategory && this.appendedCategory._id;
    },
  },
  watch: {
    editedCategory(category) {
      this.nameModel = category ? category.name : '';
    },
    appendedCategory() {
      this.nameModel = '';
    },
  },
  methods: {
    appendCategory() {
      const parent = this.appendedCategory;
      const loading = this.$loading();
      const newCategory = {
        parentId: parent._id,
        name: this.nameModel,
      };
      createCategory(newCategory)
        .then((categoryId) => {
          if (!parent.children) this.$set(parent, 'children', []);
          parent.children.push({ ...newCategory, _id: categoryId });
          this.appendedCategory = null;
        })
        .catch(() => {
          this.$message.error('Could not append category');
        })
        .finally(() => loading.close());
    },
    editCategory() {
      const loading = this.$loading();
      updateCategory({
        categoryId: this.editedCategoryId,
        category: CategorySchema.clean({ ...this.editedCategory, name: this.nameModel }),
      })
        .then(() => {
          this.editedCategory.name = this.nameModel;
          this.editedCategory = null;
        })
        .catch(() => {
          this.$message.error('Could not update name');
        })
        .finally(() => loading.close());
    },
    onNodeDrop(draggedNode, droppedNode, type) {
      const category = draggedNode.data;
      category.parentId = type === 'inner' ? droppedNode.key : droppedNode.data.parentId;
      const loading = this.$loading();
      updateCategory({
        categoryId: category._id,
        category: CategorySchema.clean(category),
      })
        .catch(() => {
          this.$message.error('Could not update, please refresh page');
        })
        .finally(() => loading.close());
    },
  },
};
</script>
