<template>
  <div>
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center" :style="{ marginBottom: '1rem' }">
        <h3>{{ $t('commons.products') }} ({{ totalProducts }})</h3>
        <el-pagination
          v-if="!isSupplierView"
          small
          layout="prev, pager, next, jumper"
          background
          :page-size="pageLimit"
          :page-count="Math.ceil(totalProducts / pageLimit)"
          :current-page="currentPage"
          :total="totalProducts"
          class-="float-right"
          @current-change="onCurrentPageChange"
        />
      </div>
      <SearchBar
        class="products-searchbar"
        :search-value="filtersAndSorts.productSearchFilter.searchInputValue"
        :placeholder="$t('product.productManagement.table.search')"
        @onClear="onProductsTextChange('')"
        @onChange="onProductsTextChange"
      />
    </div>
    <Table
      v-loading="loading"
      class="border rounded narrow-table"
      :data="tableData.data"
      :columns="columns"
      :cell-class="handleCellClass"
      :show-index="currentIndex"
      sticky-columns="1"
      :hover="hasPermissionForEditingProduct"
      @row-click="hasPermissionForEditingProduct ? $emit('on-product-click', tableData.data[$event].id) : null"
    >
      <template v-if="isFilterableColumn(PRODUCT_TABLE_HEADERS.SUPPLIER)" #filter-supplier>
        <div class="text-typography-primary d-flex flex-column align-items-end p-0">
          <el-select
            v-model="filtersAndSorts.supplierIdFilter.searchInputValue"
            filterable
            size="mini"
            :popper-append-to-body="false"
            :placeholder="$t('product.productManagement.table.searchBySupplier')"
            clearable
          >
            <el-option v-for="supplier in suppliers" :key="supplier.id" :label="supplier.name" :value="supplier.id">
            </el-option>
          </el-select>
          <Button class="p-1 px-4 mt-1" size="mini" @click="onSupplierFilterSubmit">
            {{ $t('commons.apply') }}
          </Button>
        </div>
      </template>
      <template #cell-supplier="{ rowData: { supplier } }">
        <p>{{ supplier.name }}</p>
      </template>
      <template #cell-sku="{ rowData: { sku } }">
        <p :style="{ direction: 'ltr' }">{{ sku }}</p>
      </template>
      <template #cell-catalogs="{ rowData: { catalogs } }">
        <p :style="{ direction: 'ltr' }">{{ getCatalogsName(catalogs) }}</p>
      </template>
      <template v-if="isFilterableColumn(PRODUCT_TABLE_HEADERS.CATALOGS)" #filter-catalogs>
        <div class="text-typography-primary d-flex flex-column align-items-end p-0">
          <el-input
            v-model="filtersAndSorts.catalogsSearchFilter.searchInputValue"
            clearable
            size="mini"
            class="mb-1"
            :placeholder="$t('catalog.products.table.searchCatalog')"
            @input="onCatalogTextChange"
          />
        </div>
      </template>
      <template #cell-lastOrder="{ rowData: { lastOrder } }">
        <p>
          <a v-if="lastOrder" href="" @click.prevent.stop="documentId = lastOrder.order.eventReferences[0].documentId"
            >{{ formatDate(lastOrder.date) }}
          </a>
          <template v-else>-</template>
        </p>
      </template>
      <template #cell-categoryId="{ rowData: { categoryId } }">
        <p>{{ getCategory(categoryId) }}</p>
      </template>
      <template #cell-kebab="{ rowData, rowIndex }">
        <el-dropdown
          v-if="hasPermissionForEditingProduct && rowData?.catalogs?.length"
          class="d-flex justify-content-end ms-1"
          trigger="click"
          :placement="$direction === 'rtl' ? 'bottom-start' : 'bottom-end'"
          @command="(action) => handleAction(action, rowData)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button type="icon" class="p-1 more-btn" @click.stop>
            <KebabIcon />
          </Button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="PRODUCT_ACTIONS.DELETE_CATALOG">
              <div class="delete-action">
                <p>{{ $t('catalog.products.table.removeFromCatalog') }}</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>

      <template #cell-availability="{ rowData: { availability } }">
        <p>
          <Tag v-if="typeof availability !== 'undefined'" size="mini" :type="availability ? 'success' : 'warning'">{{
            $t(`catalog.products.table.availability.${availability}`)
          }}</Tag>
          <span v-else> - </span>
        </p>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        small
        layout="prev, pager, next, jumper"
        background
        :page-size="pageLimit"
        :page-count="Math.ceil(totalProducts / pageLimit)"
        :current-page="currentPage"
        :total="totalProducts"
        class-="float-right"
        @current-change="onCurrentPageChange"
      />
    </div>
    <DocumentModal v-if="documentId" visible :document-id="documentId" @close="documentId = null" />
    <ActionsModal
      v-if="isDeleteModalOpen && catalogsToDeleteFromProduct.catalogsToRemove.length"
      :toggle-dialog="isDeleteModalOpen"
      :title="
        $t(
          catalogsToDeleteFromProduct.catalogsToRemove.length === 1
            ? 'catalog.products.removeProductFromCatalogTitle'
            : 'catalog.products.removeProductFromManyCatalogsTitle'
        )
      "
      :confirm-button-text="$t('catalog.products.removeFromCatalog')"
      :confirm-button-loading="deleteProductCatalogsLoading"
      :confirm-button-disabled="
        catalogsToDeleteFromProduct.catalogsToRemove.length > 1 &&
        catalogsToDeleteFromProduct.catalogsToRemove.every((catalog) => !catalog.selected)
      "
      :cancel-button-text="$t('cancel')"
      :dialog-type="
        catalogsToDeleteFromProduct.catalogsToRemove.length === 1 ? DIALOG_TYPES.WARNING : DIALOG_TYPES.PRIMARY
      "
      @on-cancel="onCloseDeleteLastCatalogFromProduct"
      @on-close="onCloseDeleteLastCatalogFromProduct"
      @on-confirm="onRemoveLastCatalogFromProduct"
    >
      <template #content>
        <div v-if="catalogsToDeleteFromProduct.catalogsToRemove.length === 1">
          <FormattedText
            :text="
              $t('catalog.products.removeProductFromCatalog', {
                productName: getProductNameById(catalogsToDeleteFromProduct.fromProductId),
                catalogName: products.find((product) => product.id === catalogsToDeleteFromProduct.fromProductId)
                  ?.catalogs[0].name,
              })
            "
          />
        </div>
        <div v-else>
          <p>
            <FormattedText
              :text="
                $t('catalog.products.removeProductFromMultiCatalogs', {
                  productName: getProductNameById(catalogsToDeleteFromProduct.fromProductId),
                })
              "
            />
          </p>
          <div class="mt-5 gap-2 d-flex flex-column many-products-to-remove-list">
            <div v-for="catalog in catalogsToDeleteFromProduct.catalogsToRemove" :key="catalog.id">
              <el-checkbox v-model="catalog.selected">{{ catalog.name }}</el-checkbox>
            </div>
          </div>
        </div>
      </template>
    </ActionsModal>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance, watch, reactive } from 'vue';

import { Table, Button, DIALOG_TYPES, Tag } from '@/modules/core/components';
import { DocumentModal } from '@/modules/documentModal';
import { options } from '@/locale/dateConfig';

import { getProductsTableColumns, getProductsTableData, PRODUCT_TABLE_HEADERS, sortProducts } from './products-helpers';
import { useGroupOrderItemsByProduct } from '../../compositions/order/useOrderItemsAggregationByProduct';
import { useCategories } from '../../compositions';
import SearchBar from '@/modules/core/components/SearchBar.vue';
import { debounce } from 'lodash';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';
import { useUserPermissions } from '@/modules/auth';
import { KebabIcon } from '@/assets/icons';
import { useDeleteProductInCatalog } from '@/modules/catalog/compositions/useDeleteProductInCatalog';
import ActionsModal from '@/modules/core/components/ActionsModal.vue';
import FormattedText from '@/modules/purchase-management/components/FormattedText.vue';

const PRODUCT_ACTIONS = {
  DELETE_CATALOG: 'deleteCatalog',
};

const PRODUCTS_LIMIT = 15;

export default {
  components: { Table, Button, DocumentModal, SearchBar, KebabIcon, ActionsModal, Tag, FormattedText },
  props: {
    products: { type: Array, default: () => [] },
    suppliers: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    isSupplierView: { type: Boolean, default: false },
    totalCount: { type: Number, default: null },
    limit: { type: Number, default: null },
    page: { type: Number, default: 1 },
  },
  emits: [
    'on-product-click',
    'on-supplier-search',
    'on-products-remove-from-catalogs',
    'on-product-search',
    'on-page-change',
  ],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasPermissionForEditingProduct = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.EDIT_PRODUCT);
    const searchProductInputValue = ref('');
    const selectedProductId = ref(null);
    const documentId = ref(null);
    const activeRowIndex = ref(-1);
    const isDeleteModalOpen = ref(false);
    const catalogsToDeleteFromProduct = reactive({
      catalogsToRemove: [],
      fromProductId: null,
    });
    const deleteProductCatalogsLoading = ref(false);
    const filtersAndSorts = reactive({
      productSearchFilter: {
        filterValue: '',
        searchInputValue: '',
      },
      catalogsSearchFilter: {
        filterValue: '',
        searchInputValue: '',
      },
      supplierIdFilter: {
        filterValue: '',
        searchInputValue: '',
      },
      activeSort: {
        direction: 1,
        columnKey: PRODUCT_TABLE_HEADERS.NAME,
      },
    });

    const currentPage = computed(() => props.page);
    const pageLimit = computed(() => props.limit ?? PRODUCTS_LIMIT);
    const currentIndex = computed(() => (props.page - 1) * pageLimit.value + 1);
    const products = computed(() => props.products);
    const isSupplierView = computed(() => props.isSupplierView);

    const { deleteProductInCatalog } = useDeleteProductInCatalog();
    const { getCategory } = useCategories();
    const productIds = computed(() => props.products.map(({ id }) => id));
    const { groupOrderItemsByProduct: lastOrders, loading: lastOrderLoading } = useGroupOrderItemsByProduct(
      computed(() => ({ productIds: productIds.value }))
    );

    const formatDate = (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, options.short);

    const onProductsTextChange = debounce((value) => {
      filtersAndSorts.productSearchFilter.filterValue = value;
      emit('on-product-search', value);
    }, 700);

    const onCatalogTextChange = debounce((value) => {
      filtersAndSorts.catalogsSearchFilter.filterValue = value;
    }, 700);

    const getCatalogsName = (catalogs) => {
      return catalogs?.length ? catalogs.map((catalog) => catalog.name).join(', ') : '-';
    };

    watch(
      [
        () => filtersAndSorts.productSearchFilter.filterValue,
        () => filtersAndSorts.catalogsSearchFilter.filterValue,
        () => filtersAndSorts.activeSort.direction,
        () => filtersAndSorts.activeSort.columnKey,
        () => currentPage.value,
      ],
      (newValues, oldValues) => {
        const [newProductSearchFilter, newFilterValue, newDirection, newColumnKey] = newValues;
        const [oldProductSearchFilter, oldFilterValue, oldDirection, oldColumnKey] = oldValues;

        if (
          newProductSearchFilter !== oldProductSearchFilter ||
          newFilterValue !== oldFilterValue ||
          newDirection !== oldDirection ||
          newColumnKey !== oldColumnKey
        ) {
          onCurrentPageChange(1);
        }
      }
    );

    const tableData = computed(() => {
      const productNameSearchTerms = filtersAndSorts.productSearchFilter.filterValue.trim().toLowerCase().split(/\s+/);

      let filteredProducts = isSupplierView.value
        ? products.value.filter((product) => {
            const productName = product.name.toLowerCase();
            const catalogsExist = filtersAndSorts.catalogsSearchFilter.filterValue
              ? product.catalogs.some((catalog) =>
                  catalog.name.toLowerCase().includes(filtersAndSorts.catalogsSearchFilter.filterValue.toLowerCase())
                )
              : true;

            const nameExist = productNameSearchTerms.every((term) => productName.includes(term));

            return nameExist && catalogsExist;
          })
        : products.value;

      const total = filteredProducts.length ?? 0;

      if (isSupplierView.value) {
        if (filtersAndSorts.activeSort.columnKey) {
          filteredProducts = sortProducts(
            filteredProducts,
            filtersAndSorts.activeSort.columnKey,
            filtersAndSorts.activeSort.direction
          );
        }

        filteredProducts = filteredProducts.slice(
          pageLimit.value * (currentPage.value - 1),
          pageLimit.value * currentPage.value
        );
      }

      return {
        data: filteredProducts.map((product) => getProductsTableData(product, isSupplierView.value, lastOrders.value)),
        total,
      };
    });

    const totalProducts = computed(() =>
      typeof props.totalCount === 'number' ? props.totalCount : tableData.value.total
    );

    const columns = computed(() =>
      getProductsTableColumns(
        isSupplierView.value,
        filtersAndSorts.supplierIdFilter.filterValue,
        filtersAndSorts.activeSort,
        filtersAndSorts.catalogsSearchFilter.filterValue
      )
    );

    const isFilterableColumn = (headerKey) => columns.value.find((column) => column.key === headerKey)?.isFilterable;

    const handleAction = (action, rowData) => {
      if (action === PRODUCT_ACTIONS.DELETE_CATALOG) {
        catalogsToDeleteFromProduct.catalogsToRemove = rowData.catalogs.map((catalog) => ({
          ...catalog,
          selected: false,
        }));
        catalogsToDeleteFromProduct.fromProductId = rowData.id;
        isDeleteModalOpen.value = true;
      }
    };

    const actionsVisibleChange = (rowIndex, isVisible) => {
      activeRowIndex.value = isVisible ? rowIndex : -1;
    };

    const handleCellClass = (rowIndex) => {
      if (tableData.value.data?.[rowIndex]?.availability === false) {
        return 'products-table-disabled-col';
      }

      if (rowIndex === activeRowIndex.value) {
        return 'active-row';
      }
    };

    const onCloseDeleteLastCatalogFromProduct = () => {
      isDeleteModalOpen.value = false;
    };

    const onRemoveLastCatalogFromProduct = async () => {
      deleteProductCatalogsLoading.value = true;

      try {
        for await (const catalog of catalogsToDeleteFromProduct.catalogsToRemove) {
          await deleteProductInCatalog({
            id: catalog.id,
            params: { productId: catalogsToDeleteFromProduct.fromProductId },
          });
        }
      } catch (error) {
        console.log('error while deleting product from catalog', error);
      } finally {
        emit('on-products-remove-from-catalogs');
        onCloseDeleteLastCatalogFromProduct();
        deleteProductCatalogsLoading.value = false;
      }
    };

    const getProductNameById = (productId) => products.value.find((product) => product.id === productId)?.name;

    const onCurrentPageChange = (newPage) => emit('on-page-change', newPage);

    const onSupplierFilterSubmit = () => {
      filtersAndSorts.supplierIdFilter.filterValue = filtersAndSorts.supplierIdFilter.searchInputValue;
      emit('on-supplier-search', filtersAndSorts.supplierIdFilter.filterValue);
    };

    return {
      getCategory,
      formatDate,
      selectedProductId,
      searchProductInputValue,
      productIds,
      lastOrders,
      lastOrderLoading,
      documentId,
      onProductsTextChange,
      tableData,
      columns,
      isFilterableColumn,
      PRODUCT_TABLE_HEADERS,
      hasPermissionForEditingProduct,
      PRODUCT_ACTIONS,
      handleAction,
      actionsVisibleChange,
      handleCellClass,
      currentIndex,
      getCatalogsName,
      onCloseDeleteLastCatalogFromProduct,
      onRemoveLastCatalogFromProduct,
      DIALOG_TYPES,
      getProductNameById,
      catalogsToDeleteFromProduct,
      isDeleteModalOpen,
      deleteProductCatalogsLoading,
      currentPage,
      PRODUCTS_LIMIT,
      onCurrentPageChange,
      totalProducts,
      onSupplierFilterSubmit,
      filtersAndSorts,
      pageLimit,
      onCatalogTextChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .narrow-table {
  table {
    th,
    td {
      padding: 0.7em;
    }
  }
}
::v-deep {
  .active-row {
    background-color: #f8fafb;
  }
}

tr {
  .more-btn {
    visibility: hidden;
  }
  .active-row {
    .more-btn {
      visibility: visible;
      background-color: #ecf0f3;
    }
  }
  &:hover {
    .more-btn {
      visibility: visible;
    }
  }
}

.products-searchbar {
  width: 16rem !important;
}
</style>

<style lang="css">
.products-table-disabled-col * {
  color: #9295a5;
}
.many-products-to-remove-list {
  max-height: 110px;
  overflow-y: auto;
}
</style>
